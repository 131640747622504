<script setup lang="ts">
import { OpenAPI } from "./api.generated/scion";
import { OpenAPI as SpendOpenAPI } from "@payout-link/api.generated/scion";
import { onMounted } from "vue";

OpenAPI.BASE = (import.meta.env.VITE_SCION_BASE_URL as string).split(",")[0];
SpendOpenAPI.BASE = (import.meta.env.VITE_SCION_BASE_URL as string).split(
  ","
)[0];

onMounted(() => {
  document.documentElement.style.setProperty("--bg-color-spend", "#F3F3F3");
});
</script>

<template>
  <router-view />
</template>

<style scoped></style>
